var swiper = new Swiper('.slider--home .swiper-container', {
  slidesPerView: 1,
  loop: true,
  effect: 'fade',
  autoplay: {
    delay: 7000,
  },
});

var testimonials = new Swiper('.testimonials .swiper-container', {
  slidesPerView: 1,
  loop: true,
  autoplay: {
    delay: 7000,
  },
  navigation: {
    nextEl: '.testimonials__controls--next',
    prevEl: '.testimonials__controls--prev',
  },
});

$('.home__gallery').each(function() {
  $(this).magnificPopup({
    delegate: 'a',
    type: 'image',
    gallery: {
      enabled:true
    }
  });
});
