
jQuery(window).on('scroll', function() {
  const topBarHeight = $('.header__top-bar').outerHeight();
  const headerContainer = ($('.header'));
  const headerContainerHeight = headerContainer.outerHeight();

  if ($(this).scrollTop() > headerContainerHeight) {
    $('.header').removeClass('transparent__header').css('top', -topBarHeight);
  } else {
    $('.header').addClass('transparent__header').css('top', 0);
  }
});
